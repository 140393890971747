<template>
  <div class="graphic-editor">
    <editor v-model="content" :init="setting" />
  </div>
</template>
<script>
import axios from '@/common/utils/axios'
import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入则不显示编辑器
import config from '@/common/js/mobile-editor.js'
import { onBeforeRouteLeave } from 'vue-router'
import { onMounted, ref } from 'vue'
export default {
  components: { Editor },
  props: {
    desc: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const save = ref(false)

    /**
     * 初始化编辑器相关
     */
    const content = ref(props.desc.text)
    let contentCopy = ''
    // 图片上传成功回调
    const imageCb = (url, size) => {
      axios('/h5/admin/desc/image/upload', {
        descId: props.desc.id,
        url: url,
        token: props.desc.token
      }).then(() => { }).catch(_ => { })
    }
    const setting = {
      ...config,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        config.images_upload_handler(blobInfo, success, failure, progress, props.desc.iid, imageCb)
      },
      init_instance_callback: function () {
        config.init_instance_callback()
        if (props.desc.text) {
          insertContent(props.desc.text)
        }
        contentCopy = content.value
      }
    }
    onMounted(() => {
      tinymce.init({
        plugins: ['quickbars'],
        toolbar: false,
        menubar: false,
        inline: true
        // selector: 'textarea',  // change this value according to your HTML
        // toolbar: 'fontsizeselect',
        // fontsize_formats: '8pt 10pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 24pt 36pt 48pt'
      })
    })

    /**
     * 插入dom元素至编辑器
     */
    const insertContent = (htmlTemplate) => {
      tinymce.editors[0].insertContent(htmlTemplate)
    }

    onBeforeRouteLeave((to, from, next) => {
      if (save.value || content.value === contentCopy) {
        next()
        return
      }
      window.$confirm('是否确认放弃编辑').then(() => {
        next()
      }).catch(_ => {})
    })

    return {
      content,
      setting,
      tinymce,
      insertContent
    }
  }
}
</script>
<style lang="scss" scoped>
.graphic-editor {
  border-radius: 3px;
  background-color: #fff;
  :deep .tox-tinymce{
    height: 100% !important;
  }
}
</style>
