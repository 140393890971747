// 引入方式引入node_modules里的tinymce相关文件文件
import 'tinymce/themes/silver' // 编辑器主题，不引入则报错
import 'tinymce/icons/default' // 引入编辑器图标icon，不引入则不显示对应图标

// 引入编辑器插件
import 'tinymce/plugins/advlist' // 高级列表
import 'tinymce/plugins/anchor' // 锚点
import 'tinymce/plugins/autolink' // 自动链接
import 'tinymce/plugins/autoresize' // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave' // 自动存稿
import 'tinymce/plugins/charmap' // 特殊字符
import 'tinymce/plugins/code' // 编辑源码
import 'tinymce/plugins/codesample' // 代码示例
import 'tinymce/plugins/directionality' // 文字方向
import 'tinymce/plugins/emoticons' // 表情
import 'tinymce/plugins/fullpage' // 文档属性
import 'tinymce/plugins/fullscreen' // 全屏
import 'tinymce/plugins/help' // 帮助
import 'tinymce/plugins/hr' // 水平分割线
import 'tinymce/plugins/image' // 插入编辑图片
import 'tinymce/plugins/importcss' // 引入css
import 'tinymce/plugins/insertdatetime' // 插入日期时间
import 'tinymce/plugins/link' // 超链接
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/media' // 插入编辑媒体
import 'tinymce/plugins/nonbreaking' // 插入不间断空格
import 'tinymce/plugins/pagebreak' // 插入分页符
import 'tinymce/plugins/paste' // 粘贴插件
import 'tinymce/plugins/preview' // 预览
import 'tinymce/plugins/print' // 打印
import 'tinymce/plugins/quickbars' // 快速工具栏
import 'tinymce/plugins/save' // 保存
import 'tinymce/plugins/searchreplace' // 查找替换
import 'tinymce/plugins/tabfocus' // 切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table' // 表格
import 'tinymce/plugins/template' // 内容模板
import 'tinymce/plugins/textcolor' // 文字颜色
import 'tinymce/plugins/textpattern' // 快速排版
import 'tinymce/plugins/toc' // 目录生成器
import 'tinymce/plugins/visualblocks' // 显示元素范围
import 'tinymce/plugins/visualchars' // 显示不可见字符
import 'tinymce/plugins/wordcount' // 字数统计

import { client } from './oss'

const dir = document.domain === 'www.xiaomeng.tech' ? 'prod' : 'local' // 判断是否为生产环境

export default {
  language_url: '/tinymce/zh_CN.js', // 引入语言包文件
  language: 'zh_CN', // 语言类型
  skin_url: '/tinymce/skins/ui/oxide', // 皮肤：浅色
  plugins:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern autosave ', // 插件配置
  toolbar: 'undo redo | image | forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect',
  // file_picker_types: 'media',
  image_dimensions: false,
  fontsize_formats:
        '12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 28px 32px 36px 48px 56px 72px', // 字体大小
  font_formats:
        '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
  // height: 500, // 注：引入autoresize插件时，此属性失效
  placeholder: '在这里输入文字',
  branding: false, // tiny技术支持信息是否显示
  resize: false, // 编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
  statusbar: false, // 最下方的元素路径和字数统计那一栏是否显示
  elementpath: false, // 元素路径是否显示

  content_style: 'img {max-width:100%;}', // 直接自定义可编辑区域的css样式
  // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入

  // images_upload_url: '/demo/upimg.php',  //后端处理程序的url
  // images_upload_base_path: '/demo',  //相对基本路径--关于图片上传建议查看--http://tinymce.ax-z.cn/general/upload-images.php
  // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
  // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
  // images_upload_handler: (blobInfo, success) => {
  //   const img = 'data:image/jpeg;base64,' + blobInfo.base64()
  //   success(img)
  // },
  images_upload_handler: (blobInfo, success, failure, progress, iid = '0', cb) => {
    const loading = window.$loading({ text: '0%' })
    var filename = blobInfo.filename()
    var index = filename.lastIndexOf('.')
    var suffix = filename.substring(index + 1, filename.length) // 获取文件后缀
    // 重构文件名 加上时间序列防止重名
    filename = dir + '/' + iid + '/0/' + new Date().getTime() + Math.floor(Math.random() * 150) + '.' + suffix
    client.multipartUpload(filename, blobInfo.blob(), {
      progress: p => {
        loading.setText((p * 100).toFixed() + '%')
      }
    }).then(function (result) {
      if (result.res.requestUrls) {
        loading.close()
        success(result.res.requestUrls[0].split('?')[0])
        cb && cb(result.res.requestUrls[0].split('?')[0])
      }
    }).catch(_ => { })
  },

  /**
   * 添加键盘事件监听
   */
  init_instance_callback: function () {
    const win = window.frames[0]
    // ctrl+w 上方插入空行 ctrl+s下方插入空行
    win.addEventListener('keydown', (e) => {
      if ((e.key === 'w' || e.key === 's') && e.altKey === true) {
        // 获取当前光标所在的点
        let node = window.frames[0].getSelection().getRangeAt(0).endContainer
        const body = window.frames[0].document.getElementById('tinymce')
        while (node.parentNode !== body) {
          node = node.parentNode
        }
        // 插入空行
        let dom = null
        const br = document.createElement('br')
        const p = document.createElement('p')
        p.append(br)
        if (e.key === 'w') {
          dom = body.insertBefore(p, node)
        }
        if (e.key === 's') {
          dom = body.insertBefore(p, node.nextSibling)
        }
        // 光标挪至空行
        const range = window.frames[0].getSelection().getRangeAt(0)
        range.setStart(dom, dom.textContent.length)
        range.setEnd(dom, dom.textContent.length)
      }
    }, true)
  }
}
