<template>
  <div class="desc-template">
    <mobile-editor class="mobile-editor" ref="mobileEditor" :desc="desc"></mobile-editor>
  </div>
  <div class="footer-fixed" style="bottom: {{bottom}}px;padding-bottom:{{bottom ? 0 : 'env(safe-area-inset-bottom)'}};background: none;">
    <div class="footer-group" style="background: none;">
      <button class="button" @click="saveContent">保存</button>
    </div>
  </div>
</template>
<script>
import axios from '@/common/utils/axios'
import MobileEditor from './desc-template/components/mobile-editor.vue'
import { useRoute } from 'vue-router'
import { ref, reactive, onMounted } from 'vue'
import wx from 'weixin-js-sdk'

export default {
  components: { MobileEditor },
  setup () {
    const descId = useRoute().query.descId
    const token = useRoute().query.token
    const descTemplateId = useRoute().query.descTemplateId
    const descGroupId = useRoute().query.descGroupId
    const nextDescId = useRoute().query.nextDescId
    const tempDescId = useRoute().query.tempDescId
    const tempToken = useRoute().query.tempToken
    const desc = reactive({
      id: descId,
      token
    })
    onMounted(async () => {
      if ((descId && token) || (tempDescId && tempToken)) {
        const loading = window.$loading()
        await queryDesc()
        loading.close()
      } else if (!descId) {
        desc.text = '<section><p style="margin: 20px 0; max-width: 750px; font-size: 15px; color: #333; line-height: 2em; letter-spacing: 2px;"></p></section>'
      }
    })

    /**
     * 查询图文详情
     */
    const queryDesc = () => {
      return new Promise((resolve) => {
        if (tempDescId) {
          axios('/h5/admin/desc/text', {
            descId: tempDescId,
            token: tempToken
          }, { loading: false })
            .then(res => {
              desc.text = res.desc.text
              resolve()
            }).catch(_ => { })
          return
        }
        axios('/h5/admin/desc/text', {
          descId,
          token
        }, { loading: false })
          .then(res => {
            desc.text = res.desc.text
            resolve()
          }).catch(_ => { })
      })
    }
    const saveContent = () => {
      return new Promise((resolve) => {
        if (tempDescId) {
          wx.miniProgram.postMessage({ data: mobileEditor.value.content })
          wx.miniProgram.navigateBack()
          resolve()
          return
        }
        axios('/h5/admin/desc/text/save', {
          descGroupId,
          descTemplateId,
          descId,
          nextDescId,
          token,
          text: mobileEditor.value.content
        }, { loading: false })
          .then(res => {
            wx.miniProgram.postMessage({ data: mobileEditor.value.content })
            wx.miniProgram.navigateBack()
            resolve()
          }).catch(_ => { })
      })
    }
    const mobileEditor = ref()
    return { desc, saveContent, mobileEditor }
  }
}
</script>
<style lang="scss" scoped>
$material-list-width : 460px;
$gutter-width: 10px;
.desc-template{
  display: flex;
  height: calc(100% - 100px);
  overflow: hidden;
  box-sizing: border-box;
  background-color: $bg-gray !important;
  .material-list {
    background-color: #fff;
    width: $material-list-width;
    margin-right: $gutter-width;
  }
  .mobile-editor{
    flex: 1;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 10;
  width: 100%;
  background: #ffffff;
  .footer-group {
    width: 120px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
    background: #ffffff;
    .button {
      width: 100%;
      text-align: center;
      height: 48px;
      line-height: 48px;
      color: #ffffff;
      font-size: 16px;
      background: #2E7CFE;
      border-radius: 24px;
      border-radius: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      border: 0;
    }
    &:active {
      opacity: 0.9;
    }
  }
}
</style>
